@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    @apply text-base;
    @apply text-gray-700;
    @apply text-justify;
    background-image: url(https://www.toptal.com/designers/subtlepatterns/patterns/debut_light.png);
    font-family: "Open Sans", "Helvetica", "Verdana", sans-serif
}

header {
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;
    @apply bg-white;
    @apply border-b-2;
    @apply border-gray-800;
}

#logo {
    @apply p-12;
}



nav {
    @apply ml-auto;
    @apply pr-12;
}

nav a {
    @apply p-4;
}

main {
    @apply mx-auto;
    @apply px-6;
    @apply max-w-screen-md;
    @apply py-24;
}

footer {
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply justify-between;
    @apply bg-white;
    @apply border-t-2;
    @apply border-gray-800;
    @apply p-6;
}

footer img.logo {
    height: 2rem;
}

.full-bleed {
    width: 100vw;
    margin-left: calc(50% - 50vw);
}


.deck {
    background-image: url(../assets/extra_clean_paper.png);
}

.pricing-cards {
    @apply my-24;
    @apply flex-row;
    @apply flex;
    @apply flex-row;
    @apply flex-wrap;
    @apply justify-center;
}

.pricing-card {
    background-image: url(../assets/extra_clean_paper.png);
    width: 220px;
    height: 350px;
    @apply text-center;
    @apply text-lg;
    @apply m-2;
    @apply p-4;
    @apply shadow-md;
    @apply rounded;
    @apply flex;
    @apply flex-col;
    @apply border-2;
    @apply text-xl;
}


.pricing-card p {
    @apply text-sm;
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply flex-1;
}

.pricing-card p.price {
    @apply text-xl;
    @apply font-bold;
    @apply py-8;
}


h1 {
    @apply pb-6;
    @apply text-4xl;
    @apply fancy;
}

h2 {
    @apply text-center;
    @apply my-10;
    @apply text-2xl;
}

h3 {
    @apply text-xl;
}

p {
    @apply pb-2;
    @apply leading-loose;
}

li {
   @apply my-6
}

.fancy {
    font-family: Dancing Script, cursive;
}

form label {
     @apply block;
     @apply text-sm;
     @apply font-bold;
     @apply mb-2;
}

form input, form textarea {
     @apply shadow;
     @apply appearance-none;
     @apply border;
     @apply rounded;
     @apply w-full;
     @apply py-2;
     @apply px-3;
     @apply text-gray-700;
     @apply leading-tight;
     /* @apply focus:outline-none; */
     /* @apply focus:shadow-outline; */
}

form button {
     @apply bg-blue-500;
     @apply text-white;
     @apply font-bold;
     @apply py-2;
     @apply px-4;
     @apply rounded;
}

form button:hover {
     @apply bg-blue-700;
}

form button:focus {
     @apply outline-none;
     @apply shadow-outline;
}

#message-confirmation {
    display: none;
    @apply bg-blue-100;
    @apply border-l-4;
    @apply border-blue-500;
    @apply text-blue-700;
    @apply p-10;
}

.message-sent #message-confirmation {
    display: block;
}

p a[href] {
    @apply underline;
}
